.section-header {
  margin-top: calc(48px + 16px);
  margin-bottom: 48px;
}
.section-header p {
  font-family: 'Raleway', 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo',
    sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .section-header {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}
